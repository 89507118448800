import React from "react"
import { Link } from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default ({ location }) => (
      <Layout>
        <Seo
          pagetitle="WORKS"
          pagedesc="作品一覧"
          pagepath={location.pathname}
         />
        <div id="pageBody" className="wrapper">
          <Link to = {`/`}>
            <div className="nav">
              <svg className="switchButton" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.41 52.83">
                <g id="レイヤー_2" data-name="レイヤー 2"><g id="UI">
                <path d="M91.35,4.52c5-2.56,5.75-3,5.71-3.66l0-.28A4.78,4.78,0,0,1,98.21,0a6,6,0,0,0,.47,1.25,6.42,6.42,0,0,0,.73,1.08A4.41,4.41,0,0,1,98.25,3L98,2.77c-.52-.43-1.33,0-6.3,2.52l-83.68,43c-5,2.56-5.61,2.92-5.66,3.64l0,.3a4.45,4.45,0,0,1-1.18.58,5.89,5.89,0,0,0-.46-1.22A6.11,6.11,0,0,0,0,50.5a4.44,4.44,0,0,1,1.16-.62l.26.16c.61.38,1.28.06,6.25-2.49Z"/>
                <path d="M5.53,11.05c-.54,0-.52,0-.71.56L4,14.13c-.42,1.24-.15,1.28.63,1.41l.61.1c.14.15.12.53,0,.61-.68,0-1.47,0-2.33-.06-.7,0-1.35,0-2.08.06a.46.46,0,0,1,0-.61l.62-.1c.61-.11.84-.27,1.16-1,.25-.54.59-1.42,1-2.56L6.44,4.54A3.53,3.53,0,0,0,6.83,3,3.56,3.56,0,0,0,8.3,2a.43.43,0,0,1,.36.16C9,3.11,9.27,4.1,9.61,5l3,8.8c.48,1.41.59,1.53,1.66,1.74l.31.06a.45.45,0,0,1,0,.61c-.86,0-1.63-.06-2.73-.06-.9,0-1.82,0-2.56.06a.47.47,0,0,1-.06-.61l.46-.06c.76-.1.86-.23.55-1.11l-1-2.86c-.19-.56-.27-.56-.86-.56Zm2.63-1c.58,0,.67,0,.5-.54L7.78,7a17.4,17.4,0,0,0-.61-1.75h0c-.09.15-.3.76-.65,1.75l-1,2.45c-.21.61-.17.61.34.61Z" fill="#A0A0A0"/>
                <path d="M18.9,7.92c0,.29,0,.38.17.38a4.17,4.17,0,0,1,2.2-.68,3.92,3.92,0,0,1,4,4.16c0,3-2.22,4.7-5,4.7a9.53,9.53,0,0,1-2.8-.56.71.71,0,0,0-.52.33.34.34,0,0,1-.15,0c-.08,0-.21-.08-.21-.14s.06-1.2.06-2V4.68c0-1.4,0-1.53-.48-1.82l-.29-.19c-.13-.09-.15-.42,0-.48.79-.24,1.89-.68,2.75-1a.28.28,0,0,1,.29.17c0,.84-.06,2.14-.06,3Zm0,4.91a3.56,3.56,0,0,0,.46,2.1,1.75,1.75,0,0,0,1.41.71c1.4,0,2.18-1.32,2.18-3.1,0-2.46-1.3-3.78-2.79-3.78a2,2,0,0,0-1,.27c-.19.13-.23.4-.23,1.11Z" fill="#A0A0A0"/>
                <path d="M36.19,12a4.31,4.31,0,0,1-4.6,4.53,4.15,4.15,0,0,1-4.43-4.28,4.47,4.47,0,0,1,4.6-4.58A4.22,4.22,0,0,1,36.19,12Zm-4.34,3.69c1.44,0,1.86-1.72,1.86-3.33,0-1.77-.6-3.85-2.16-3.85-1.07,0-1.91,1.1-1.91,3.43C29.64,13.8,30.33,15.64,31.85,15.64Z" fill="#A0A0A0"/>
                <path d="M46.51,13.1c0,1.18,0,2.23.53,2.23a3.2,3.2,0,0,0,.9-.15.48.48,0,0,1-.06.72,13.26,13.26,0,0,0-3.45.79.25.25,0,0,1-.19-.21,3.55,3.55,0,0,0,.07-.77c0-.17-.09-.23-.21-.23a5.73,5.73,0,0,1-2.86,1c-1.89,0-2.5-1.05-2.5-2.79V10.42c0-1.47-.08-1.64-.61-1.79l-.29-.08c-.13-.11-.13-.49,0-.55a11.08,11.08,0,0,0,2.92-.38.26.26,0,0,1,.21.19A14.47,14.47,0,0,0,41,9.6v3.23c0,1.24.23,2.39,1.72,2.39a2.06,2.06,0,0,0,1.38-.52c.21-.21.26-.4.26-1.18V10.35c0-1.34-.13-1.42-.93-1.68l-.33-.1a.4.4,0,0,1,0-.57,11.64,11.64,0,0,0,3.29-.38.25.25,0,0,1,.21.19c0,.4-.06,1-.06,1.79Z" fill="#A0A0A0"/>
                <path d="M52.73,8.84c-.37,0-.39,0-.39.72v3.23c0,1.32.18,2.5,1.53,2.5a1.64,1.64,0,0,0,1-.38.33.33,0,0,1,.27.42,3.3,3.3,0,0,1-2.67,1.15c-2,0-2.35-1.15-2.35-2.62V9.6c0-.74,0-.76-.38-.76H49A.35.35,0,0,1,49,8.27a6.4,6.4,0,0,0,1.66-.88,3.91,3.91,0,0,0,1.16-1.3.46.46,0,0,1,.59.11V7.46c0,.33,0,.39.4.39h2.07c.13.07.17.24.17.45a.64.64,0,0,1-.27.54Z" fill="#A0A0A0"/>
                <path d="M63.59,38.09c-1,.21-1.3.68-2.1,2.38-1.58,3.33-2.92,6.42-4.1,9.36-.31.78-.48,1.34-.67,1.74a.65.65,0,0,1-.36.09.6.6,0,0,1-.37-.09c-.44-1.3-1.77-4-2.61-5.81-.94,1.91-1.87,3.84-2.66,5.81a.68.68,0,0,1-.36.09.63.63,0,0,1-.38-.09c-.33-.94-.86-2.08-1.47-3.36l-4-8.58c-.44-1-.65-1.28-1.51-1.49L42.36,38a.41.41,0,0,1,.06-.59c.76,0,1.64.06,2.54.06s1.56,0,2.57-.06a.47.47,0,0,1,.06.59l-.53.12c-.27.07-.48.15-.48.34a5.24,5.24,0,0,0,.5,1.41l2.5,5.41c.74,1.6,1.31,2.73,1.51,3.07.45-.84.91-1.79,1.41-2.88a3.15,3.15,0,0,0,.3-.79,2.56,2.56,0,0,0-.24-.72l-2.2-4.66c-.4-.84-.65-1-1.22-1.18L48.53,38a.42.42,0,0,1,.07-.59c.86,0,1.55.06,2.26.06s1.66,0,2.5-.06a.42.42,0,0,1,0,.59l-.56.12c-.23,0-.36.15-.36.3a1.82,1.82,0,0,0,.19.69c.46,1,1,2.23,1.47,3.17.36-.71.78-1.59,1.11-2.37a5.07,5.07,0,0,0,.46-1.45c0-.15-.12-.25-.42-.31L54.58,38a.43.43,0,0,1,.06-.59c.57,0,1.16.06,2,.06s1.23,0,1.91-.06a.4.4,0,0,1,0,.61l-.53.1c-.77.15-1,.4-1.66,1.64-.46.84-1,2-1.44,2.92a2.66,2.66,0,0,0-.21.59,2.24,2.24,0,0,0,.16.5l.91,2c.44.94,1,2.24,1.21,2.6,1.14-2.5,2.29-5.29,3.41-8a9.13,9.13,0,0,0,.65-2c0-.15-.51-.21-.84-.32l-.36-.1a.36.36,0,0,1,0-.59c.78,0,1.43.06,2.14.06s1.37,0,2-.06a.42.42,0,0,1,.06.59Z"/>
                <path d="M71.83,47.08a4.31,4.31,0,0,1-4.59,4.54,4.15,4.15,0,0,1-4.43-4.29,4.46,4.46,0,0,1,4.59-4.58A4.21,4.21,0,0,1,71.83,47.08Zm-4.34,3.7c1.45,0,1.87-1.73,1.87-3.34,0-1.77-.61-3.85-2.17-3.85-1.07,0-1.91,1.1-1.91,3.43C65.28,48.93,66,50.78,67.49,50.78Z"/>
                <path d="M74.62,45.74c0-1,0-1.2-.52-1.52l-.26-.14a.6.6,0,0,1,0-.49,14.35,14.35,0,0,0,2.65-1.13.28.28,0,0,1,.29.19c0,.4,0,.76,0,.94a.23.23,0,0,0,.21.26c.65-.47,1.34-1.1,2.06-1.1a1.06,1.06,0,0,1,1.15,1A1.16,1.16,0,0,1,79,45a1,1,0,0,1-.57-.17,2,2,0,0,0-1-.4c-.48,0-.61.49-.61,1.52v2.87c0,1.56.09,1.79.82,1.87l.82.09c.15.14.13.52,0,.6-1.26,0-2-.06-2.75-.06s-1.39,0-2.18.06c-.17-.08-.19-.46,0-.6l.38-.07c.72-.12.8-.33.8-1.89Z"/>
                <path d="M82.15,39.82c0-1.41,0-1.54-.55-1.87l-.23-.15c-.13-.08-.15-.42,0-.48.8-.23,1.89-.68,2.75-1a.27.27,0,0,1,.29.17c0,.84-.06,2.14-.06,3v6.66c0,.25.06.29.46.29s.76-.15,1.37-.8l.82-.9c.44-.48.61-.71.61-.82s-.09-.19-.34-.21l-.57-.06a.39.39,0,0,1,0-.63h.61a29.81,29.81,0,0,0,3.28-.17c.19.08.19.46,0,.63l-.38.06a2.77,2.77,0,0,0-1.53.72c-.73.65-1.45,1.26-1.78,1.61a.29.29,0,0,0,0,.42,36.46,36.46,0,0,0,3,3.87,2,2,0,0,0,1.2.56l.21,0c.12.16.12.52-.07.6-.69,0-1.47-.06-2.33-.06s-1.41,0-2.27.06a.38.38,0,0,1,0-.6l.21,0c.21,0,.4-.14.4-.25A1,1,0,0,0,87,50l-1.72-2.42a.88.88,0,0,0-.65-.42c-.26,0-.32.09-.32.65v1c0,1.56.09,1.79.8,1.89l.46.07c.15.14.13.52,0,.6-.88,0-1.6-.06-2.37-.06s-1.39,0-2.25.06c-.17-.08-.19-.46,0-.6l.44-.07c.71-.1.8-.33.8-1.89Z"/>
                <path d="M92.91,50.94a6.05,6.05,0,0,1-.3-2.37.42.42,0,0,1,.59,0c.38,1.17,1.16,2.29,2.27,2.29a1.13,1.13,0,0,0,1.18-1.18c0-.78-.7-1.28-1.43-1.64C94.13,47.48,93,46.7,93,45.36c0-1.64,1.39-2.61,3.13-2.61a4.67,4.67,0,0,1,1.95.42,4.94,4.94,0,0,1,.32,2,.43.43,0,0,1-.57.11c-.31-1-1-1.77-1.81-1.77a1.12,1.12,0,0,0-1.17,1.09c0,.61.61,1,1.41,1.43,1.13.55,2.33,1.26,2.33,2.92,0,1.83-1.62,2.63-3.28,2.63A3.89,3.89,0,0,1,92.91,50.94Z"/>
                </g></g>
              </svg>
            </div>
          </Link>
            <div className="works">
              <h3>2023</h3>
              <p><a href="https://ambr.co.jp/casestudy/169/" target="_blank" rel="noreferrer">TOKYO GAME SHOW VR 2023 <FontAwesomeIcon icon={faArrowUpRightFromSquare} /><span>体験ディレクション(as ambr CXO)</span></a></p>
              <p><a href="https://about.fb.com/ja/news/2023/09/meta_immersive_academy_vr_short_film_showcase/" target="_blank" rel="noreferrer">N/S高等学校「メタバース学園ドラマ制作プロジェクト」 <FontAwesomeIcon icon={faArrowUpRightFromSquare} /><span>特別講師</span></a></p>
              <p><Link to = {`/isonote/`}>VR楽器 "isonote"<span>個人制作</span></Link></p>
              <p>X社VRサービスプロトタイピング<span>コンセプト設計・VR体験アドバイザリー</span></p>
              <br />
              <h3>2022</h3>
              <p><a href="https://ambr.co.jp/casestudy/112/" target="_blank" rel="noreferrer">TOKYO GAME SHOW VR 2022 <FontAwesomeIcon icon={faArrowUpRightFromSquare} /><span>体験ディレクション(as ambr CXO)</span></a></p>
              <p>N/S高等学校「メタバースの学校プロジェクト」<span>ワークショップ・アドバイザリー、講師</span></p>
              <p><a href="https://ambr.co.jp/casestudy/53/" target="_blank" rel="noreferrer">マジック：ザ・ギャザリング バーチャルアート展 <FontAwesomeIcon icon={faArrowUpRightFromSquare} /><span>体験ディレクション(as ambr CXO)</span></a></p>
              <p><Link to = {`/sizeless/`}>SIZELESS TWIN<span>デジタルツイン空間制作</span></Link></p>
              <br />
              <h3>2021</h3>
              <p><Link to = {`/tgsvr2021/`}>TOKYO GAME SHOW VR 2021<span>エクスペリエンス・ディレクション</span></Link></p>
              <p>PARALLEL CITY 構想<span>コンセプト設計, VR空間体験設計アドバイザリー</span></p>
              <p><Link to = {`/tokeidai/`}>MPUF XRCC バーチャル時計台<span>デジタルツイン空間制作</span></Link></p>
              <br />
              <h3>2020</h3>
              <p><Link to = {`/dc02/`}>Virtual Market 5 "DEFAULT CUBE"<span>VR空間体験設計・制作</span></Link></p>
              <p><Link to = {`/dc01/`}>Virtual Market 4 "DEFAULT CUBE"<span>VR空間体験設計・制作</span></Link></p>
              <p><a href="https://vraa.jp/" target="_blank" rel="noreferrer">VRAA(Virtual Reality Architecture Award) <FontAwesomeIcon icon={faArrowUpRightFromSquare} /><span>アワード企画・設計・運営</span></a></p>
              <br />
              <h3>2019</h3>
              <p><Link to = {`/neoshibuya/`}>Virtual Market 3 "NEO SHIBUYA"<span>VR空間体験設計・制作</span></Link></p>
              <p><Link to = {`/ambrgallery/`}>ambr "バーチャルギャラリー"<span>VR空間体験設計・制作</span></Link></p>
              <p><Link to = {`/vm/`}>Virtual Market 2 "VIRTUAL MUSEUM"<span>VR空間体験設計・制作</span></Link></p>
              <p>BEYOND REALITY<span>VR空間設計(協力)</span></p>
              <br />
              <h3>2018</h3>
              <p><Link to = {`/nuclides/`}>TABLE OF NUCLIDES<span>企画, VR空間体験設計・制作</span></Link></p>
              <p><Link to = {`/newton/`}>BUILT UNBUILT<span>個人制作</span></Link></p>
              <p><Link to = {`/iburi/`}>胆振東部地震フォトグラメトリー<span>VR空間設計(協力)</span></Link></p>
              </div>
            <div className="works event">
              <h3>EVENT, LECTURE, MEDIA</h3>
              <p>イベント登壇・出演・メディア掲載等</p>
              <p>Forbes JAPAN / WIRED CONFERENCE / CGWORLD / UI UX Camp! 2023 / FORUM21 経済・産業分科会 / 東京大学大学院情報理工学系研究科知能機械情報学専攻「技術とコンテンツ」 / 東京藝術大学「特論 建築情報学」 / 九州大学芸術工学部 / 建築雑誌 / 建築情報学会 / JIA中国支部 / JSCA / 建築士会フォーラム / テレビ朝日「発進！ミライクリエイター」 / J-WAVE「GROWING REED」「INNOVATION WORLD」 / 物性研究所計算物質科学研究センター / 日経XTECH / 日経BP / MoguLive / SHIBUYA QWS</p>
              <p>他多数</p>
            </div>
        </div>
        <div className="footer">
          <p>© 2022 idiomorph</p>
        </div>
      </Layout>
)
